$font-stack-bold: 'IntroCondBold',"Helvetica Neue",Arial,  sans-serif;
$font-stack: 'IntroCondRegular',"Helvetica Neue",Arial, sans-serif;
$font-info: "Helvetica Neue",Arial, sans-serif;
@mixin no-select{
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
}
$lfui-black: #222222;
$lfui-blue: #005aa0;
$lfui-navy: #00427A;
$lfui-smoke: #D9D9D9;
$lfui-red: #E30613;
$lfui-wine: #AD1015;
$lfui-gray: #747578;
$lfui-white: #FFFFFF;
$lfui-shadow: #E5E5E5;
$lfui-body-bg: #F3F3F3;
$lfui-lf-success: #D8EBAE;
$lfui-silver:#B1B3B6;

$validation-error-label: #ff2727;
$validation-error-background: #fff1f1;
$disabled-input-background: #fcfcfc;
$disabled-input-foreground: #d7d7d7;
$disabled-input-background-darker: #eFeFeF;
$input-text:#8a8a8f;