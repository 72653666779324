@import '../../variables.scss';

div#agreement-signee {
    p#sign-disclaimer{
        font-family:$font-info;
        font-size:11px;
        margin-left:16px;
        color:$lfui-gray;
    }
}
