@import '../../variables.scss';

#testmode-container {
  h4{
    margin:0;
  }  

  button {
    width:auto;
    padding: 10px 15px;
    float: right;
    font-size: 16px;
    &:hover {
      box-shadow: inset 0 0 0 1px $lfui-navy;      
    }

    &:disabled {
      cursor: unset;
      color:$lfui-smoke;
      border-color:$lfui-smoke;
      &:hover {
        box-shadow: unset; 
        background-color:#fff;     
      }
  
    }
  }
  .info-text {
    font-family: $font-stack;
    display: inline-block;
    margin-top: 0;
  }
}