@import '../../variables.scss';
button.button-left-arrow  {
    color: $lfui-wine;
    font-weight: 700;
    text-decoration: none;
    text-align: left;
    background: 0 0;
    border: none;
    padding: 0;
    margin: 0;
    outline: none;

    &:hover {
        box-shadow: unset;
        cursor: pointer;
    }

    img{
        margin-right: 10px;
    }
}



