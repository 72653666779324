@import '../../variables.scss';

div.amount-exceeds-disclaimer-container {
    p#sign-disclaimer{
        font-family:$font-info;
        font-size:1rem;
        margin-top:10px;
        color:$lfui-red;
    }
}
