@import '../../variables.scss';

div#organization-number-lookup-div {
  margin: 25px 15px;

  h3 {
    margin-bottom: 10px;
  }

  form {
    overflow: hidden;
  }

  
}