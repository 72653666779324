@import '../../variables.scss';
div.input-container{
    border-width:1px;
    border-color:$disabled-input-foreground;
    border-style:solid;
    padding: 5px 2px 7px 15px;
    &.edit-mode{
        background-color: #eefaff;
        transition:background-color 0.50s ease-in-out;
        input, label {
            // color:$lfui-blue;
        }

    }
    &.invalid {
        background-color:$validation-error-background;
        border-color:$validation-error-label;
        label{
            color:$validation-error-label;
        }
      }
      &.disabled{
        transition:background-color 1.50s ease-in-out;

            background-color:$disabled-input-background-darker;
            border-color:$disabled-input-foreground;
            color:$input-text;
        label, input{
            color:$input-text;
        }
      }
      &.required {
        border-color:$validation-error-label;
      }
      &.hideError{
          background-color: inherit;
          border-color: $disabled-input-foreground;
          label{
            color:$input-text;
        }
      }
    ::placeholder{
        color:$disabled-input-foreground;
    }

    label{
        padding:0;
        margin:0;
        line-height: 12px;
        font-size:11px;
        font-family: $font-info;
        color: $input-text;
        span{
            font-size:0.75rem;
        }
    }
    span.take-space{
        display:inline-block
    }
    input {
        width: 100%;
        font-weight: normal;
        border-radius: 0;
    }
    
    input[type=text],
    input[type=email] {
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        padding: 0px 0px 0px 0px;
        font-size: 16px;
        outline: none;
        background-color: transparent;
        font-family: Arial;
    }

    
        input[type=text].is-valid,
        input[type=email].is-valid,
        textarea.is-valid {
            padding-right: 45px !important;
            background-position: right 15px top 17px;
            background-repeat: no-repeat;
            background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIyMHB4IiBoZWlnaHQ9IjE0cHgiIHZpZXdCb3g9IjAgMCAyMCAxNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgICAgIDx0aXRsZT5pY19jaGVjazwvdGl0bGU+ICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPiAgICA8ZGVmcz48L2RlZnM+ICAgIDxnIGlkPSJTbGFzayIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+ICAgICAgICA8ZyBpZD0iVGVzdC1uZXctZmVlZGJhY2stc2lkYTQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC02MzAuMDAwMDAwLCAtMTgyMi4wMDAwMDApIj4gICAgICAgICAgICA8ZyBpZD0iaW5wdXQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDQwMC4wMDAwMDAsIDE4MDUuMDAwMDAwKSI+ICAgICAgICAgICAgICAgIDxnIGlkPSJpY19jaGVjayIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMjMwLjAwMDAwMCwgMTUuMDAwMDAwKSI+ICAgICAgICAgICAgICAgICAgICA8cmVjdCBpZD0iUmVjdGFuZ2xlLTYiIHg9IjAiIHk9IjAiIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+PC9yZWN0PiAgICAgICAgICAgICAgICAgICAgPHBvbHlnb24gaWQ9IlBhZ2UtMSIgZmlsbD0iIzQ1RDU1MCIgcG9pbnRzPSIxOC41NDczNDc4IDIgNy4xOTcyNzgyMSAxMy4xMjUzNDQyIDEuNDY4MTE2OTEgNy40Mjk3OTI3OCAwIDguODUzOTM0MjEgNy4xODI4NDQ0OCAxNiAyMCAzLjQzODM0MjI3Ij48L3BvbHlnb24+ICAgICAgICAgICAgICAgIDwvZz4gICAgICAgICAgICA8L2c+ICAgICAgICA8L2c+ICAgIDwvZz48L3N2Zz4=);
        }
    
        input[type=text].invalid,
        input[type=email].invalid,
        textarea.invalid {
            border-bottom-color: #e30613 !important;
        }
    

}

