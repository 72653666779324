@import '../../variables.scss';
.signing-complete-container{
    margin:25px 15px;
    img{
        height: 70px;
        margin-left: -15px;
    }
    h1 {
        margin-top: 0; 
    }

    p {
        font-size: 24px;
        line-height: 24px;
        font-family: $font-info;
    }

    .clearfix{
        overflow:auto;
    }
}

.signing-complete-spinner {
    text-align: center;
    
    h1, h2, h3{
        margin-top: 50px;
        font-family: $font-stack-bold;
        display: inline-block;
    }

    .lf-spinner{
        display: inline-block;
        padding: 10px;
        background-color: transparent;
        border: unset;
    }
}

@media (max-width: 767px) {
    .signing-complete-container{
        p {
            font-size: 14px;
            line-height: 14px;
            font-family: $font-info;
        } 
    }
}