@import '../../variables.scss';

.red-corner {
  width: 24px;
  position: absolute;
  top: 4px;
  left: 4px;
  @include no-select;
}

.wrapper {
  padding: 25px 15px;
}

.container {
  border: 4px solid #0265aa;
  padding: 25px;
  position: relative;
}

.header {
  font-family: 'IntroCondBold', Arial, "Helvetica Neue", sans-serif;
  margin: 0;
  color: #005aa0;
  font-size: 1.17em;
  padding: 0;
  line-height: 1.3;
}

.content {
  padding-top: 10px;
  margin-top: 10px;
}
  