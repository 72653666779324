@import '../../variables.scss';

.financing-info-container {
    overflow: hidden;
    padding: 20px 20px;
    margin: 25px 15px 30px 15px;
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    border-color: #ccc;

    p {
        margin-block-end: 0.5em;
        margin-block-start: 0.5em;
    }
}

.financing-info__header {
    font-family: $font-stack-bold;
    font-size: 18px;
    line-height: 18px;
    // color: $lfui-black;
    margin-bottom: 10px;
    display: block;
}

.financing-info__text {
    p {
        // color: $lfui-black;
        font-size: 11px;
        font-weight: normal;
        line-height: 12px;
        font-family: $font-info;
    }
}

.total-cost-container {
    text-align: center;

    .total-cost-header{
        padding: 0;
        color: $lfui-blue;
        font-size: 14px;
        line-height: 22px;
        margin-block-start: 0;
        margin-block-end: 5px;
    }
    .large-paragraph {
        color: $lfui-blue;
        font-size: 30px;
        font-weight: bold;
        line-height: 30px;
        font-family: $font-stack-bold;
        margin-block-start: 0;
        margin-block-end: 0;
    }
    p.exvat_text {
        text-align: center;
        padding: 0;
        font-size: 0.875rem;
        margin-top:5px;
        color: $lfui-blue;
    }
}