@import '../../variables.scss';
@mixin nice-margin{
    margin:5px 0px 10px 0px;
} 
div#customer-address-container {
    margin: 25px 15px;
    h2 {
        font-family: $font-stack-bold;
        margin-bottom:4px;
    }
   
    h5 {
        font-family: $font-stack;
        @include nice-margin;
    }
    p{
        font-family: $font-info;
        font-size:14px;
        line-height: 22px;
        margin:0;
    }

    .delivery-address-container{
        display: flex;
        margin-bottom: 7px;
        width: 100%;
        h4 {
            font-family: $font-stack-bold;
            margin-bottom:0px;
            margin-top:4px;
            display: inline-block;
        }

        div.info-box {
            display: inline-block;
            background-color: $disabled-input-background-darker;
            font-size: 11px;
            padding: 7px 10px;
            margin-left: 20px;
            border-radius: 5px;
        }
    }
    button.change-address-button{
        float:none;
        margin:0 0 0 10px;
        padding:0;
        height: auto;
        width: auto;
        font-family: arial;
        font-size: 14px;
        background-color: white;
        color:$lfui-blue;
        svg{
            width:24px;
            height:auto;
            vertical-align: sub;
            
        }
        &.undo{
            svg{
                width:16px;
                margin:4px;
            }
        }
    }
    div.address-item{
        .selectable{
            padding:10px;
            &:hover{
                background-color:#efefef;
            }
        }
    }

    input[type=checkbox]{
        width:unset;
        cursor: pointer;
    }
    input[type=radio]{
        position: absolute;
        left: -9999px;
    }

    [type=radio]:checked + label,
    [type=radio]:not(:checked) + label
    {
        position: relative;
        padding-left: 28px;
        cursor: pointer;
        line-height: 20px;
        display: inline-block;
        color: #333;
        &:before{
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            font-size:1px;
            width: 13px;
            height: 13px;
            border: 2px solid #ddd;
            border-radius: 100%;
            background: #fff;
        
        }
        &:after{
            content: '';
            width: 9.2px;
            height: 9.2px;
            background: $lfui-blue;
            position: absolute;
            top: 4px;
            left: 4px;
            border-radius: 9.2px; 
        }
    }

    [type=radio]:not(:checked) + label:after {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    [type=radio]:checked + label:after {
        opacity: 1;

        -webkit-transform: scale(1);
        transform: scale(1);
    }
    [type=radio]:checked + label:before {
        opacity: 1;
        border-color:$lfui-blue;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
   
}

@media (max-width: 767px) {
    div#customer-address-container {
        .delivery-address-container{
            div.info-box {
                margin-left: 0px;
            }
        }
    }
}

