.sign-agreement {
    margin: 10px 40px 20px 40px;

    button {
        width: 100%;
        margin-bottom: 10px;
    }
    
    .inner-content {
        display: inline-block;
        width: 100%;
        font-size:1.125rem;
    }
}

@media (max-width: 767px) {
    .sign-agreement {
        margin: 10px 15px 20px 15px;
    }    
}



